import axios from 'axios'
import config from '../config'

const getTermsConditions = function (platform) {
  const url = `${config.usersUrl}/content/termsCondition?platform=${platform}`
  return axios.get(url)
}

const getPrivacyPolicy = function (platform) {
  const url = `${config.usersUrl}/content/privacyPolicy?platform=${platform}`
  return axios.get(url)
}

const getUserAndAgreement = function (platform) {
  const url = `${config.usersUrl}/content/user-end-agreement?platform=${platform}`
  return axios.get(url)
}

export const termsConditionsService = {
  getTermsConditions,
  getPrivacyPolicy,
  getUserAndAgreement
}
