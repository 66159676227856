<template>
  <div class="mainBody terms_condition_body">
    <Header />
    <div class="mainContainer container">
      <!-- titlebar -->
      <div id="titlebar" class="terms_condtion_title menu">
        <div class="container">
          <div class="d-flex align-center">
            <h3 class="ma-0">{{ title }}</h3>
          </div>
        </div>
      </div>
      <div class="listMenu">
        <div class="title" @click="menuState = !menuState">
          <img
            class="burgerMenu"
            src="@/assets/images/svgs/GreenBurgerMenu.svg"
            alt="Green Menu Icon"
            loading="lazy"
          />
          <span class="list"> القائمه </span>
        </div>
      </div>

      <div v-if="menuState" class="menu">
        <!-- CHANGE TO HECTAR PLUS PLATFORM -->
        <p
          class="menuTitle title cursor ma-0"
          @click="changeSelectedHectar('hectarPlus')"
          :class="{ selected: selectedHectar == 'hectarPlus' }"
        >
          منصه هكتار بلس
        </p>

        <!-- HECTAR PLUS -->
        <ul class="list" v-if="selectedHectar == 'hectarPlus'">
          <li
            v-for="li in list"
            :key="li.type"
            class="cursor"
            :class="{ selected: selectedContentType == li.type }"
            @click="changeContentType(li.type)"
            v-text="li.title"
          />
        </ul>

        <!-- CHANGE TO HECTAR PLATFORM -->
        <p
          class="menuTitle title cursor ma-0 mt-3"
          @click="changeSelectedHectar('hectar')"
          :class="{ selected: selectedHectar == 'hectar' }"
        >
          منصه هكتار
        </p>

        <!-- HECTAR -->
        <ul class="list" v-if="selectedHectar == 'hectar'">
          <li
            v-for="li in list"
            :key="li.type"
            class="cursor"
            :class="{ selected: selectedContentType == li.type }"
            @click="changeContentType(li.type)"
            v-text="li.title"
          />
        </ul>
      </div>

      <!-- Container -->
      <div class="terms_condition_container">
        <div class="contentContainer" v-if="content.length > 0">
          <div
            class="contentContainerBody"
            v-for="contentData in content"
            :key="contentData._id"
          >
            <div v-html="sanitize(contentData.text)" class="content"></div>
          </div>
        </div>
        <div class="contentContainer" v-else>
          <div class="contentContainerBody pa-5">لا يوجد محتوى</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/termsConditionsHeader.vue'
import { termsConditionsService } from '@/services/termsConditions.service.js'
import sanitizers from '@/mixins/sanitizers.js'
export default {
  name: 'TermsConditions',
  mixins: [sanitizers],
  components: { Header },
  data() {
    return {
      title: 'الشروط والأحكام',
      menuState: false,
      windowWidth: 0,
      selectedHectar: this.$route.query.type ?? 'hectarPlus',
      selectedContentType: 'serviceCondition',
      list: [
        {
          title: 'شروط الخدمة',
          type: 'serviceCondition'
        },
        {
          title: 'سياسة الخصوصية',
          type: 'privacyPolicy'
        },
        {
          title: 'إتفاقية الإشتراك والإستخدام',
          type: 'subscribeAndUse'
        }
      ],
      terms: [],
      privacyPolicy: [],
      userAndAgreement: [],
      isPageLoading: false
    }
  },
  computed: {
    content() {
      if (this.selectedContentType === 'serviceCondition') {
        return this.terms
      }
      if (this.selectedContentType === 'subscribeAndUse') {
        return [this.userAndAgreement]
      }
      return this.privacyPolicy
    },
    breadcrumbs() {
      return [
        {
          text: this.title,
          disabeled: true
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    showMenu(width) {
      this.menuState = width > 1040
    },
    onResize() {
      this.windowWidth = window.innerWidth
      this.showMenu(this.windowWidth)
    },
    changeContentType(contentType) {
      if (contentType !== this.selectedContentType) {
        this.selectedContentType = contentType
      }
    },
    async changeSelectedHectar(hectarType) {
      if (this.selectedHectar !== hectarType) {
        this.selectedHectar = hectarType
        await this.getPageData()
      }
    },
    async getPageData() {
      try {
        this.isPageLoading = true
        const [
          termsConditionsResponse,
          privacyPolicyResponse,
          userAndAgreementResponse
        ] = await Promise.all([
          await termsConditionsService.getTermsConditions(this.selectedHectar),
          await termsConditionsService.getPrivacyPolicy(this.selectedHectar),
          await termsConditionsService.getUserAndAgreement(this.selectedHectar)
        ])
        this.terms = termsConditionsResponse.data.result
        this.privacyPolicy = privacyPolicyResponse.data.result
        this.userAndAgreement = userAndAgreementResponse.data.result
      } catch {
        this.addNotifications({
          title: 'حدث خطأ ما برجاء المحاوله',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    }
  },
  async created() {
    this.windowWidth = window.innerWidth
    this.showMenu(this.windowWidth)
    await this.getPageData()
  }
}
</script>

<style lang="scss">
@import '@/styles/termsConditions/termsConditions.scss';
</style>
