<template>
    <header>
        <!-- Header -->
        <div class="headerWrapper">
            <div id="headerr">
                <!-- <div id="header"> -->
                <div class="container">
                    <div class="headerInner">
                        <div class="rightContent">
                            <router-link :to="{path:'/dashboard'}">
                                <img src="@/assets/images/svgs/terms_conditions/hectarLogo.svg" alt="Site Logo" loading="lazy">
                            </router-link>
                        </div>
                        <div>
                        </div>
                        <div class="d-flex leftContent">
                            <div class="dropdown">               
                                <button @click="menuState = !menuState" class="dropdownbtn mt-3 ml-5">
                                    <img width="20px" src="@/assets/images/svgs/googleDots.svg" loading="lazy" alt="Dots Icon"/>
                                </button>
                                 <div v-if="menuState" class="hectarTypeMenu">             
                                    <ul class="list">
                                        <li v-for="(type, i) in hectarType" :key="i">
                                           <a :href="type.link">
                                                <img :src="type.icon" alt="Type Icon">
                                                <span>{{type.title}}</span>
                                           </a>
                                        </li>                                       
                                    </ul>              
                                </div>
                            </div>                                             
                            <div class="mt-2" v-if="loggedIn">
                                <div class="d-flex nameContainer">
                                    <h6 v-if="currentUserName" class="substringName">
                                        {{ currentUserName ? subString(currentUserName) : "" }}
                                    </h6>
                                    <div class="left">
                                        <p class="hi">حياك الله</p>
                                        <h5 v-if="currentUserName" class="name">
                                            {{ currentUserName }}
                                        </h5>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                            </div>
                            <div class="loginBtn mt-4" v-else>
                                <button variant="primary" @click="loginPageRoute"
                                      class="m-0">
                                    <span class="text">تسجيل دخول</span>
                                </button>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Header / End -->
    </header>
</template>

<script>
    export default {
        name: 'Header',        
        data() {
            return {
                menuState: false,
                hectarType: [
                    {
                        title: "منصه هكتار",
                        icon: require("@/assets/images/svgs/HectarPlatform.svg"),
                        link: "https://www.hectar.io/"
                    },
                    {
                        title: "منصه هكتار بلس",
                        icon: require("@/assets/images/svgs/HectarPlusPlatform.svg"),
                        link: "https://www.hectarplus.io/"
                    },
                ],
                loggedIn: '',
                currentUserName: ''
            };
        },
        methods: {
            loginPageRoute() {
                this.$router.push('/auth/login');
            },
            subString(val) {
                let x = val.replace(" ", ",");
                let arr = x.split(",");
                let str = arr.map((str) => {
                    return str.substring(0, 1);
                });
                return (val = str.join(" "));
            },
            
        },
        created(){
            this.loggedIn = JSON.parse(localStorage.getItem("logged_in"));
            this.currentUserName = JSON.parse(localStorage.getItem("tokenData")).name;
        }
    };
</script>
<style lang="scss" scoped>
@import "@/styles/helpCenter/helpCenterHeader.scss";
</style>
